html, body, #root {
  height: 100%;
}

.side-panel {
  top: 0;
  order: 1;
  flex-grow: 1;
  position: sticky;
  padding: 0 var(--pf-global--spacer--lg) var(--pf-global--spacer--lg) var(--pf-global--spacer--2xl);
  align-self: flex-start;
  max-height: 100%;
  overflow-y: auto;
  /* Hide scrollbar IE, Edge & Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
